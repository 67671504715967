import { Add, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Container,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import PrimaryButton from "src/componentsV2/buttons/PrimaryButton";
import SecondaryButton from "src/componentsV2/buttons/SecondaryButton";
import { useUrlSearch } from "../../components/urlSearch";
import AvailabilityPicker from "../../componentsV2/AvailabilityPicker";
import { Loading } from "../../componentsV2/Loading";
import { OneClickBackground } from "../../componentsV2/OneClickBackground";
import OneClickLogoImage from "../../componentsV2/OneClickLogoImage";
import useGeneralNotifications from "../../hooks/useGeneralNotifications";
import {
  useGuestAccept,
  useGuestDecline,
  useGuestProposeNewTime,
} from "../../mutations";
import {
  useHostAvailability,
  useValidateGuestDeclineAction,
} from "../../queries";
import { useValidateGuestAction } from "../../queries/useValidateGuestAction";
import { GuestActionSource, GuestAction as GuestActionType } from "../../types";
import { useLogoUrl } from "../../utils/logoUrl";
import InvalidUrl from "../InvalidUrl";
import BorderBox from "../../componentsV2/BorderBox";
import Confirmation from "../../componentsV2/confirmation/Confirmation";

function Accept({
  orgId,
  meetingId,
  contactId,
}: {
  orgId: string;
  meetingId: string;
  contactId: string;
}) {
  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const guestAccept = useGuestAccept();

  useEffect(
    () => {
      guestAccept(contactId, meetingId, orgId)
        .catch((err) => {
          if (err?.status === 409) {
            history.push("/pending");
          } else {
            history.push("/error");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    // We only want this to run if the ids change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactId, meetingId, orgId],
  );

  if (loading) {
    return <Loading />;
  }

  return <Redirect to="/accepted" />;
}

function AcceptWithConfirmation({
  orgId,
  meetingId,
  contactId,
}: {
  orgId: string;
  meetingId: string;
  contactId: string;
}) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const theme = useTheme();

  const guestAccept = useGuestAccept();

  const handleConfirmationClick = () => {
    setLoading(true);
    setConfirmed(true);
    guestAccept(contactId, meetingId, orgId)
      .catch((err) => {
        if (err?.status === 409) {
          history.push("/pending");
        } else {
          history.push("/error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  if (confirmed) {
    return <Redirect to="/accepted" />;
  }

  return (
    <Container maxWidth="xs">
      <Stack
        alignItems="center"
        spacing={2}
        sx={{ minHeight: "100vh", paddingTop: theme.spacing(4) }}
      >
        <BorderBox>
          <Confirmation
            title="Confirmation Required"
            message="Please Confirm The Meeting"
            caption="To prevent false accepts caused by external email 
            scanners, we require additional confirmation for meetings 
            accepted within 30 minutes of the invite being sent."
            buttonText="Confirm"
            icon={
              <InfoOutlined
                sx={{
                  color: theme.palette.primary.main,
                  height: "5em",
                  width: "5em",
                }}
              />
            }
            onOkClicked={handleConfirmationClick}
          />
        </BorderBox>
      </Stack>
    </Container>
  );
}

function Decline({
  orgId,
  meetingId,
  contactId,
}: {
  orgId: string;
  meetingId: string;
  contactId: string;
}) {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const theme = useTheme();

  const guestDecline = useGuestDecline();

  const { error } = useValidateGuestDeclineAction(orgId, meetingId, contactId);
  if (error) {
    return <Redirect to="/error" />;
  }

  const handleConfirmationClick = () => {
    setLoading(true);
    setConfirmed(true);
    guestDecline(contactId, meetingId, orgId)
      .catch((err) => {
        if (err?.status === 409) {
          history.push("/pending");
        } else {
          history.push("/error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleProposeTime = () => {
    const query = new URLSearchParams(search);
    query.set("a", "propose_new_time");
    const url = `${pathname}?${query.toString()}`;

    history.push(url);
  };

  if (loading) {
    return <Loading />;
  }

  if (confirmed) {
    return <Redirect to="/declined" />;
  }

  return (
    <Container maxWidth="xs">
      <Stack
        alignItems="center"
        spacing={2}
        sx={{ minHeight: "100vh", paddingTop: theme.spacing(8) }}
      >
        <Typography>Are you sure you want to decline this meeting?</Typography>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <SecondaryButton nowrap onClick={handleConfirmationClick}>
            Decline
          </SecondaryButton>
          <PrimaryButton icon={<Add />} nowrap onClick={handleProposeTime}>
            Propose Time
          </PrimaryButton>
        </Stack>
      </Stack>
    </Container>
  );
}

function ProposeNewTime({
  orgId,
  meetingId,
  contactId,
  source,
}: {
  orgId: string;
  meetingId: string;
  contactId: string;
  source: GuestActionSource | null;
}) {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.up("md"));

  const [proposedTime, setProposedTime] = useState<dayjs.Dayjs | null>(null);
  const [selection, setSelection] = useState<dayjs.Dayjs | null>(null);
  const [confirmed, setConfirmed] = useState(false);
  const url = useLocation<{ url: string }>().state?.url;

  const { addError } = useGeneralNotifications();

  const guestProposeNewTime = useGuestProposeNewTime();

  const { data, loading, error } = useHostAvailability(
    orgId,
    meetingId,
    contactId,
  );

  const handleMeetingGuestAction = () => {
    guestProposeNewTime(
      contactId,
      meetingId,
      orgId,
      proposedTime as Dayjs,
      source,
    )
      .then(() => {
        setConfirmed(true);
      })
      .catch((error: { message: string }) => {
        addError(`could not submit time proposal: ${error.message}`);
      });
  };

  useEffect(() => {
    if (!data?.availableTimes?.[0]) {
      return;
    }

    const firstAvailableTime = dayjs(data.availableTimes[0]);
    setProposedTime(firstAvailableTime);
    setSelection(firstAvailableTime);
  }, [data]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    switch (error.status) {
      case 409:
        if (error.response?.body?.message) {
          return <Redirect to="/pending" />;
        }
        return <Redirect to="/error" />;
      case 400:
        return <InvalidUrl />;
      default:
        return <Redirect to="/error" />;
    }
  }

  if (!data) {
    return <Redirect to="/error" />;
  }

  if (confirmed) {
    return <Redirect to="/accepted" />;
  }

  if (data.availableTimes.length === 0) {
    return (
      <Container maxWidth="sm">
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "100vh" }}
        >
          <Typography variant="h4" align="center">
            The meeting host has no availability at this time
          </Typography>
        </Stack>
      </Container>
    );
  }

  return (
    <OneClickBackground>
      <Stack alignItems="center" sx={{ marginTop: theme.spacing(4) }}>
        <Paper
          elevation={0}
          sx={{ maxWidth: "fit-content", padding: theme.spacing(4) }}
        >
          <Stack alignItems="center">
            <Box sx={{ marginBottom: "25px" }}>
              {url ? (
                <a href={url}>
                  <OneClickLogoImage />
                </a>
              ) : (
                <OneClickLogoImage />
              )}
            </Box>
          </Stack>
          <Typography variant="h5">Select a date and time</Typography>
          <AvailabilityPicker
            variant={sm ? "horizontal" : "vertical"}
            date={proposedTime}
            selection={selection}
            availableSlots={data.availableTimes.map((d) => dayjs(d)) || []}
            onDateChange={setProposedTime}
            onSelectionChange={(dt) => {
              setSelection(dt);
              setProposedTime(dt);
            }}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            paddingTop={theme.spacing(2)}
          >
            <PrimaryButton
              onClick={handleMeetingGuestAction}
              disabled={selection == null}
            >
              Confirm
            </PrimaryButton>
          </Stack>
        </Paper>
      </Stack>
    </OneClickBackground>
  );
}

export default function Page() {
  document.title = "Processing Meeting Guest Action";

  const query = useUrlSearch();

  // Get the Org's UUID
  const org = query.get("o");
  // Get the Meeting's UUID
  const meeting = query.get("m");
  // Get the Contact's UUID
  const contact = query.get("c");
  // Get the Contact's Action
  const action = query.get("a") as GuestActionType | null;
  // Get the Contact's Action Source
  const actionSource = query.get("s") as GuestActionSource | null;

  if (
    org == null ||
    meeting == null ||
    contact == null ||
    action == null ||
    !["propose_new_time", "accept", "decline"].includes(action)
  ) {
    return <InvalidUrl />;
  }

  const {
    data: validationData,
    loading,
    error,
  } = useValidateGuestAction(action, org, meeting, contact);

  const [, setLogoUrl] = useLogoUrl();
  useEffect(() => {
    if (validationData?.logoURL) {
      setLogoUrl(validationData.logoURL);
    }
  }, [validationData?.logoURL]);

  if (loading) {
    return null;
  }

  if (error) {
    return <InvalidUrl />;
  }

  switch (action) {
    case "accept":
      // Manual confirmation is required if the meeting is being accepted within 30 minutes to prevent
      // automated scanners from crawling links and causing unintended automatic acceptance.
      return !validationData?.secondsSinceLastEmailSent ||
        validationData?.secondsSinceLastEmailSent > 1800 ? (
        <Accept orgId={org} meetingId={meeting} contactId={contact} />
      ) : (
        <AcceptWithConfirmation
          orgId={org}
          meetingId={meeting}
          contactId={contact}
        />
      );
    case "decline":
      return <Decline orgId={org} meetingId={meeting} contactId={contact} />;
    case "propose_new_time":
      return (
        <ProposeNewTime
          orgId={org}
          meetingId={meeting}
          contactId={contact}
          source={actionSource}
        />
      );
    default:
      return <InvalidUrl />;
  }
}
